const pathData1 =
  "M2.9375 2.53125C2.70898 2.53125 2.53125 2.73438 2.53125 2.9375V11.0625C2.53125 11.291 2.70898 11.4688 2.9375 11.4688H11.0625C11.2656 11.4688 11.4688 11.291 11.4688 11.0625V2.9375C11.4688 2.73438 11.2656 2.53125 11.0625 2.53125H2.9375Z"
const pathData2 =
  "M1.3125 2.9375C1.3125 2.04883 2.02344 1.3125 2.9375 1.3125H11.0625C11.9512 1.3125 12.6875 2.04883 12.6875 2.9375V11.0625C12.6875 11.9766 11.9512 12.6875 11.0625 12.6875H2.9375C2.02344 12.6875 1.3125 11.9766 1.3125 11.0625V2.9375Z"
const pathData3 =
  "M5.17188 3.75H7.8125C8.92969 3.75 9.84375 4.66406 9.84375 5.78125C9.84375 6.7207 9.20898 7.50781 8.3457 7.73633L9.51367 9.28516C9.7168 9.56445 9.66602 9.94531 9.38672 10.1484C9.10742 10.3516 8.72656 10.2754 8.52344 10.0215L6.89844 7.8125H5.78125V9.64062C5.78125 9.99609 5.50195 10.25 5.17188 10.25C4.81641 10.25 4.5625 9.99609 4.5625 9.64062V7.20312V4.35938C4.5625 4.0293 4.81641 3.75 5.17188 3.75ZM7.20312 6.59375H7.8125C8.24414 6.59375 8.625 6.23828 8.625 5.78125C8.625 5.34961 8.24414 4.96875 7.8125 4.96875H5.78125V6.59375H7.20312Z"

const definition = {
  prefix: "fac",
  iconName: "square-south-african-rand-sign",
  icon: [13, 14, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareSouthAfricanRandSign }
