const pathData1 =
  "M3.29297 0.875H5.78125C7.61328 0.875 9.17188 1.96875 9.91016 3.5H10.5938C10.9492 3.5 11.25 3.80078 11.25 4.15625C11.25 4.53906 10.9492 4.8125 10.5938 4.8125H10.3203C10.3477 5.03125 10.375 5.25 10.375 5.46875C10.375 5.71484 10.3477 5.93359 10.3203 6.125H10.5938C10.9492 6.125 11.25 6.42578 11.25 6.78125C11.25 7.16406 10.9492 7.4375 10.5938 7.4375H9.91016C9.17188 8.99609 7.61328 10.0625 5.78125 10.0625H3.375V12.4688C3.375 12.8516 3.07422 13.125 2.71875 13.125C2.33594 13.125 2.0625 12.8516 2.0625 12.4688V9.40625V7.4375H1.40625C1.02344 7.4375 0.75 7.16406 0.75 6.78125C0.75 6.42578 1.02344 6.125 1.40625 6.125H2.0625V4.8125H1.40625C1.02344 4.8125 0.75 4.53906 0.75 4.15625C0.75 3.80078 1.02344 3.5 1.40625 3.5H2.0625V2.13281C2.0625 1.44922 2.60938 0.875 3.29297 0.875Z"
const pathData2 = "M8.40625 3.5C7.80469 2.70703 6.84766 2.1875 5.78125 2.1875H3.375V3.5H8.40625Z"
const pathData3 =
  "M3.375 4.8125V6.125H8.98047C9.03516 5.93359 9.0625 5.71484 9.0625 5.46875C9.0625 5.25 9.03516 5.03125 8.98047 4.8125H3.375Z"
const pathData4 = "M8.40625 7.4375H3.375V8.75H5.78125C6.84766 8.75 7.80469 8.25781 8.40625 7.4375Z"

const definition = {
  prefix: "fac",
  iconName: "philippine-peso-sign",
  icon: [11, 14, [], null, `${pathData1} ${pathData2} ${pathData3} ${pathData4}`],
}

export { definition as facPhilippinePesoSign }
