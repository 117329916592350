const pathData1 =
  "M13.5,2.5 C13.775,2.5 14,2.725 14,3 L14,13 C14,13.275 13.775,13.5 13.5,13.5 L3.5,13.5 C3.225,13.5 3,13.275 3,13 L3,3 C3,2.725 3.225,2.5 3.5,2.5 L13.5,2.5 Z M3.5,1 C2.397,1 1.5,1.897 1.5,3 L1.5,13 C1.5,14.103 2.397,15 3.5,15 L13.5,15 C14.603,15 15.5,14.103 15.5,13 L15.5,3 C15.5,1.897 14.603,1 13.5,1 L3.5,1 Z"
const pathData2 =
  "M5.47,8.477 L4.97,8.477 C4.695,8.477 4.47,8.702 4.47,8.977 C4.47,9.252 4.695,9.477 4.97,9.477 L5.723,9.477 C6.31,10.967 7.766,12.023 9.466,12.023 L10.751,12.023 C11.166,12.023 11.501,11.689 11.501,11.273 C11.501,10.858 11.166,10.523 10.751,10.523 L9.466,10.523 C8.623,10.523 7.879,10.111 7.42,9.477 L9.97,9.477 C10.245,9.477 10.47,9.252 10.47,8.977 C10.47,8.702 10.245,8.477 9.97,8.477 L6.988,8.477 C6.96,8.323 6.945,8.164 6.945,8.002 C6.945,7.82 6.963,7.645 6.998,7.477 L9.97,7.477 C10.245,7.477 10.47,7.252 10.47,6.977 C10.47,6.702 10.245,6.477 9.97,6.477 L7.454,6.477 C7.916,5.87 8.645,5.477 9.466,5.477 L10.751,5.477 C11.166,5.477 11.501,5.142 11.501,4.727 C11.501,4.311 11.166,3.977 10.751,3.977 L9.466,3.977 C7.785,3.977 6.341,5.011 5.741,6.477 L4.97,6.477 C4.695,6.477 4.47,6.702 4.47,6.977 C4.47,7.252 4.695,7.477 4.97,7.477 L5.476,7.477 C5.454,7.648 5.441,7.823 5.441,8.002 C5.441,8.161 5.451,8.32 5.47,8.477 Z"

const definition = {
  prefix: "fac",
  iconName: "square-euro-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareEuroSign }
