const pathData1 =
  "M9.841,9.537 L10.252,9.772 C10.453,9.887 10.68,9.947 10.913,9.947 L11.014,9.947 C11.364,9.947 11.648,9.662 11.648,9.312 C11.648,9.044 11.481,8.805 11.232,8.715 L10.156,8.322 C10.127,8.312 10.099,8.301 10.072,8.289 C9.407,8.009 8.972,7.354 8.972,6.626 C8.972,5.789 9.54,5.085 10.31,4.88 L10.31,4.429 C10.31,4.105 10.57,3.845 10.892,3.844 L10.893,3.844 C11.215,3.845 11.475,4.105 11.475,4.429 L11.475,4.87 C11.65,4.903 11.822,4.954 11.988,5.021 L12.204,5.107 C12.503,5.227 12.648,5.568 12.529,5.868 C12.41,6.168 12.07,6.314 11.771,6.194 L11.553,6.107 C11.477,6.077 11.399,6.052 11.32,6.034 C11.201,6.006 11.079,5.992 10.956,5.992 L10.77,5.992 C10.667,5.992 10.57,6.017 10.483,6.061 C10.278,6.166 10.136,6.38 10.136,6.628 C10.136,6.789 10.197,6.94 10.3,7.054 C10.368,7.129 10.454,7.189 10.552,7.224 L11.628,7.617 C12.34,7.876 12.812,8.555 12.812,9.313 C12.812,10.15 12.244,10.854 11.474,11.059 L11.474,11.509 C11.474,11.833 11.213,12.094 10.89,12.094 C10.568,12.094 10.307,11.833 10.307,11.509 L10.307,11.042 C10.087,10.986 9.873,10.901 9.673,10.786 L9.262,10.551 C8.984,10.391 8.885,10.034 9.046,9.753 C9.206,9.474 9.561,9.376 9.841,9.537 Z"
const pathData2 =
  "M8.719,6.64 C8.719,7.564 8.174,8.359 7.39,8.724 L8.192,10.83 C8.306,11.132 8.156,11.471 7.853,11.586 C7.551,11.701 7.213,11.55 7.098,11.247 L6.219,8.937 L5.545,8.937 L5.545,11.039 C5.545,11.364 5.284,11.625 4.96,11.625 C4.636,11.625 4.375,11.364 4.375,11.039 L4.375,4.929 C4.375,4.605 4.636,4.344 4.96,4.344 L6.425,4.344 C7.692,4.344 8.719,5.372 8.719,6.64 Z M6.425,7.765 C7.046,7.765 7.549,7.262 7.549,6.64 C7.549,6.019 7.046,5.515 6.425,5.515 L5.545,5.515 L5.545,7.765 Z"
const pathData3 =
  "M1.5,3.031 C1.5,1.909 2.409,1 3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 Z M3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-brazilian-real-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareBrazilianRealSign }
