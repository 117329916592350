const svgPathData =
  "M11.458,13.794 C11.218,14.133 10.748,14.212 10.411,13.97 L4.433,9.685 C4.171,9.493 4.058,9.152 4.155,8.842 C4.252,8.53 4.546,8.318 4.871,8.318 L7.006,8.318 C8.097,8.318 9.023,7.601 9.341,6.609 L4.871,6.609 C4.455,6.609 4.12,6.273 4.12,5.857 C4.12,5.44 4.455,5.104 4.871,5.104 L9.341,5.104 C9.023,4.113 8.099,3.396 7.006,3.396 L4.871,3.396 C4.455,3.396 4.12,3.059 4.12,2.643 C4.12,2.226 4.455,1.89 4.871,1.89 L12.129,1.89 C12.545,1.89 12.88,2.226 12.88,2.643 C12.88,3.059 12.545,3.396 12.129,3.396 L10.105,3.396 C10.49,3.884 10.765,4.467 10.887,5.104 L12.129,5.104 C12.545,5.104 12.88,5.44 12.88,5.857 C12.88,6.273 12.545,6.609 12.129,6.609 L10.887,6.609 C10.549,8.376 9.041,9.727 7.205,9.818 L11.283,12.744 C11.621,12.986 11.699,13.456 11.458,13.794 Z"

const definition = {
  prefix: "fac",
  iconName: "indian-rupee-sign",
  icon: [17, 16, [], null, svgPathData],
}

export { definition as facIndianRupeeSign }
