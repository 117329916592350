const pathData1 =
  "M4.885,4.875 C5.219,4.875 5.49,5.144 5.49,5.478 L5.49,9.857 L6.555,9.857 C6.888,9.857 7.159,10.126 7.159,10.46 C7.159,10.794 6.888,11.062 6.555,11.062 L4.885,11.062 C4.552,11.062 4.281,10.794 4.281,10.46 L4.281,5.478 C4.281,5.144 4.552,4.875 4.885,4.875 Z"
const pathData2 =
  "M7.18,5.893 C7.18,5.331 7.639,4.875 8.201,4.875 L9.122,4.875 C9.456,4.875 9.727,5.144 9.727,5.478 C9.727,5.812 9.456,6.08 9.122,6.08 L8.389,6.08 L8.389,7.366 L8.788,7.366 C9.121,7.366 9.392,7.635 9.392,7.969 C9.392,8.303 9.121,8.571 8.788,8.571 L8.389,8.571 L8.389,9.857 L9.122,9.857 C9.456,9.857 9.727,10.126 9.727,10.46 C9.727,10.794 9.456,11.062 9.122,11.062 L8.201,11.062 C7.639,11.062 7.18,10.607 7.18,10.045 L7.18,5.893 Z"
const pathData3 =
  "M9.844,5.478 C9.844,5.143 10.117,4.875 10.45,4.875 L12.3,4.875 C12.633,4.875 12.906,5.143 12.906,5.478 C12.906,5.813 12.633,6.08 12.3,6.08 L11.982,6.08 L11.982,9.857 L12.3,9.857 C12.633,9.857 12.906,10.125 12.906,10.46 C12.906,10.795 12.633,11.062 12.3,11.062 L10.45,11.062 C10.117,11.062 9.844,10.795 9.844,10.46 C9.844,10.125 10.117,9.857 10.45,9.857 L10.768,9.857 L10.768,6.08 L10.45,6.08 C10.117,6.08 9.844,5.813 9.844,5.478 Z"
const pathData4 =
  "M1.5,3.031 C1.5,1.909 2.409,1 3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 Z M3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-romanian-leu-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3} ${pathData4}`],
}

export { definition as facSquareRomanianLeuSign }
