const pathData1 =
  "M15.9426 3.84878L15.6288 3.71856C15.397 3.62235 15.1564 3.54915 14.9115 3.50091L14.7904 3.47707V2.69248C14.7904 2.28594 14.4754 1.96672 14.0945 1.96545H14.0926C13.7115 1.96646 13.3961 2.28578 13.3961 2.69248V3.48344L13.2857 3.51366C12.2319 3.80222 11.4488 4.79763 11.4488 5.98682C11.4488 7.02101 12.0486 7.94754 12.9585 8.34307C12.9965 8.35955 13.0349 8.37511 13.0739 8.38972L14.6383 8.97762C15.0622 9.13626 15.3412 9.55311 15.3412 10.0132C15.3412 10.617 14.8654 11.1164 14.2692 11.1164H14.1232C13.7793 11.1164 13.4432 11.0301 13.142 10.865C13.1228 10.8544 13.1036 10.8435 13.0846 10.8323L13.0048 10.7852L12.4869 10.4798C12.155 10.2831 11.7315 10.4008 11.5384 10.7486C11.3431 11.1004 11.4642 11.5446 11.7984 11.7429L12.396 12.0953C12.6741 12.2598 12.9719 12.3824 13.2794 12.4622L13.3917 12.4914V13.3076C13.3917 13.7149 13.708 14.0346 14.09 14.0346C14.4719 14.0346 14.7882 13.7149 14.7882 13.3076V12.5189L14.8986 12.4887C15.9525 12.2001 16.7356 11.2047 16.7356 10.0155C16.7356 8.93815 16.0846 7.97783 15.1104 7.61257L13.5456 7.02453C13.3774 6.96158 13.2319 6.85777 13.1179 6.72702C12.9447 6.52852 12.8431 6.26719 12.8431 5.98911C12.8431 5.5624 13.0797 5.18977 13.4281 5.00641C13.5742 4.92945 13.7399 4.88594 13.9151 4.88594H14.1851C14.3759 4.88594 14.5652 4.90837 14.7498 4.95276C14.8484 4.97648 14.9459 5.00657 15.0414 5.04276C15.065 5.05171 15.0885 5.06103 15.1119 5.07073L15.4283 5.20111C15.7818 5.34672 16.1892 5.1707 16.3336 4.79603C16.4786 4.41966 16.2991 3.99585 15.9426 3.84878Z"
const pathData2 =
  "M4.12647 8.01562V12.2163C4.12647 12.6167 4.44959 12.9403 4.84955 12.9403C5.2495 12.9403 5.57262 12.6167 5.57262 12.2163V3.77396C5.57262 3.37352 5.2495 3.04993 4.84955 3.04993C4.44959 3.04993 4.12647 3.37352 4.12647 3.77396V6.56756H1.71058V3.77396C1.71058 3.37352 1.38746 3.04993 0.987507 3.04993C0.587553 3.04993 0.264435 3.37352 0.264435 3.77396V12.2163C0.264435 12.6167 0.587552 12.9403 0.987507 12.9403C1.38746 12.9403 1.71058 12.6167 1.71058 12.2163V8.01562H4.12647Z"
const pathData3 =
  "M9.73751 12.6254L8.35922 8.75413L7.87403 9.86739V12.2152C7.87403 12.6155 7.55285 12.9392 7.15509 12.9392C6.75732 12.9392 6.43614 12.6155 6.43614 12.2152V3.77399C6.43614 3.3737 6.75732 3.05005 7.15509 3.05005C7.55285 3.05005 7.87403 3.3737 7.87403 3.77399V7.29912L8.0287 7.32782L8.23338 6.79531L9.67389 3.57055C9.78095 3.34061 9.9223 3.17653 10.093 3.10515C10.265 3.03329 10.4624 3.05666 10.677 3.19117C10.8947 3.32764 10.9695 3.526 10.9624 3.75094C10.9554 3.97406 10.8678 4.22488 10.7618 4.47015L9.42433 7.46738L10.9218 11.8045L10.9257 11.8139C11.1009 12.1677 11.065 12.5863 10.7394 12.8124C10.5768 12.9254 10.3706 12.9702 10.1832 12.9415C9.99541 12.9127 9.82517 12.8099 9.73796 12.6263L9.73751 12.6254Z"

const definition = {
  prefix: "fac",
  iconName: "hong-kong-dollar-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facHongKongDollarSign }
