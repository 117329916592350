const pathData1 =
  "M3.05595 0.875C3.41142 0.875 3.7122 1.17578 3.7122 1.53125V3.08984L7.4583 1.99609C7.81377 1.91406 8.16923 2.10547 8.27861 2.46094C8.36064 2.81641 8.16923 3.17188 7.81377 3.28125L3.7122 4.45703V5.71484L7.4583 4.62109C7.81377 4.53906 8.16923 4.73047 8.27861 5.08594C8.36064 5.44141 8.16923 5.79688 7.81377 5.90625L3.7122 7.08203V11.8125H5.65361C7.8958 11.8125 9.72783 10.0898 9.80986 7.84766L9.8372 7.62891C9.8372 7.27344 10.138 7 10.5208 7C10.8763 7.02734 11.1497 7.32812 11.1497 7.71094L11.1224 7.92969C10.9856 10.8281 8.57939 13.125 5.65361 13.125H3.05595C2.67314 13.125 2.3997 12.8516 2.3997 12.4688V7.4375L1.68877 7.65625C1.3333 7.73828 0.977828 7.54688 0.868453 7.19141C0.786422 6.83594 0.977828 6.48047 1.3333 6.37109L2.3997 6.07031V4.8125L1.68877 5.03125C1.3333 5.11328 0.977828 4.92188 0.868453 4.56641C0.786422 4.21094 0.977828 3.85547 1.3333 3.74609L2.3997 3.44531V1.53125C2.3997 1.17578 2.67314 0.875 3.05595 0.875Z"

const definition = {
  prefix: "fac",
  iconName: "turkish-lira-sign",
  icon: [11, 14, [], null, `${pathData1}`],
}

export { definition as facTurkishLiraSign }
