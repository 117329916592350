const pathData1 =
  "M4.34375 0C4.69922 0 5 0.300781 5 0.65625V1.75H5.54688C7.16016 1.75 8.5 3.08984 8.5 4.70312C8.5 5.46875 8.19922 6.15234 7.73438 6.67188C8.69141 7.16406 9.375 8.14844 9.375 9.29688C9.375 10.9375 8.03516 12.25 6.42188 12.25H5V13.3438C5 13.7266 4.69922 14 4.34375 14C3.96094 14 3.6875 13.7266 3.6875 13.3438V12.25H1.74609C1.11719 12.25 0.625 11.7578 0.625 11.1289V7.65625V7.49219V6.34375V2.78906C0.625 2.21484 1.0625 1.75 1.63672 1.75H3.6875V0.65625C3.6875 0.300781 3.96094 0 4.34375 0Z"
const pathData2 =
  "M3.6875 3.0625H1.9375V6.34375H3.6875V3.0625ZM5 6.34375H5.54688C6.44922 6.34375 7.1875 5.63281 7.1875 4.70312C7.1875 3.80078 6.44922 3.0625 5.54688 3.0625H5V6.34375Z"
const pathData3 =
  "M3.6875 7.65625H1.9375V10.9375H3.6875V7.65625ZM5 10.9375H6.42188C7.32422 10.9375 8.0625 10.2266 8.0625 9.29688C8.0625 8.39453 7.32422 7.65625 6.42188 7.65625H5.54688H5V10.9375Z"

const definition = {
  prefix: "fac",
  iconName: "thai-baht-sign",
  icon: [9, 14, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facThaiBahtSign }
