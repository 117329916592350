const pathData1 =
  "M6.43,3.814 L8.5,6.851 L10.567,3.817 L10.567,3.817 C10.768,3.52 11.17,3.446 11.465,3.644 C11.761,3.843 11.84,4.245 11.637,4.542 L11.637,4.542 L9.827,7.201 L10.797,7.201 C11.155,7.201 11.444,7.489 11.444,7.847 C11.444,8.205 11.155,8.494 10.797,8.494 L9.147,8.494 L9.147,9.036 L10.797,9.036 C11.155,9.036 11.444,9.324 11.444,9.682 C11.444,10.04 11.155,10.328 10.797,10.328 L9.147,10.328 L9.147,11.823 C9.147,12.181 8.858,12.469 8.5,12.469 C8.142,12.469 7.853,12.181 7.853,11.823 L7.853,10.328 L6.203,10.328 C5.845,10.328 5.556,10.04 5.556,9.682 C5.556,9.324 5.845,9.036 6.203,9.036 L7.853,9.036 L7.853,8.494 L6.203,8.494 C5.845,8.494 5.556,8.205 5.556,7.847 C5.556,7.489 5.845,7.201 6.203,7.201 L7.173,7.201 L5.363,4.542 L5.363,4.542 C5.16,4.246 5.238,3.842 5.533,3.642 C5.827,3.443 6.229,3.519 6.43,3.814 Z"
const pathData2 =
  "M1.5,3.031 C1.5,1.909 2.409,1 3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 Z M3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-yen-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareYenSign }
