const pathData1 =
  "M2 12.6875H9.7793C10.1156 13.2016 10.5449 13.6473 11.0453 14H2C1.03477 14 0.25 13.2152 0.25 12.25V6.125C0.25 5.15977 1.03477 4.375 2 4.375H12.5C13.1508 4.375 13.7168 4.72773 14.0176 5.25547C13.9492 5.25273 13.8809 5.25 13.8125 5.25C13.0961 5.25 12.4152 5.40586 11.8055 5.6875H2C1.75937 5.6875 1.5625 5.88438 1.5625 6.125V12.25C1.5625 12.4906 1.75937 12.6875 2 12.6875ZM12.2812 2.1875C12.6449 2.1875 12.9375 2.48008 12.9375 2.84375C12.9375 3.20742 12.6449 3.5 12.2812 3.5H2.21875C1.85508 3.5 1.5625 3.20742 1.5625 2.84375C1.5625 2.48008 1.85508 2.1875 2.21875 2.1875H12.2812ZM10.9688 0C11.3324 0 11.625 0.292578 11.625 0.65625C11.625 1.01992 11.3324 1.3125 10.9688 1.3125H3.53125C3.16758 1.3125 2.875 1.01992 2.875 0.65625C2.875 0.292578 3.16758 0 3.53125 0H10.9688ZM13.8125 6.125C14.8568 6.125 15.8583 6.53984 16.5967 7.27827C17.3352 8.01669 17.75 9.01821 17.75 10.0625C17.75 11.1068 17.3352 12.1083 16.5967 12.8467C15.8583 13.5852 14.8568 14 13.8125 14C12.7682 14 11.7667 13.5852 11.0283 12.8467C10.2898 12.1083 9.875 11.1068 9.875 10.0625C9.875 9.01821 10.2898 8.01669 11.0283 7.27827C11.7667 6.53984 12.7682 6.125 13.8125 6.125ZM14.25 8.3125C14.25 8.07187 14.0531 7.875 13.8125 7.875C13.5719 7.875 13.375 8.07187 13.375 8.3125V9.625H12.0625C11.8219 9.625 11.625 9.82187 11.625 10.0625C11.625 10.3031 11.8219 10.5 12.0625 10.5H13.375V11.8125C13.375 12.0531 13.5719 12.25 13.8125 12.25C14.0531 12.25 14.25 12.0531 14.25 11.8125V10.5H15.5625C15.8031 10.5 16 10.3031 16 10.0625C16 9.82187 15.8031 9.625 15.5625 9.625H14.25V8.3125Z"

const definition = {
  prefix: "fac",
  iconName: "rectangle-history-circle-plus",
  icon: [18, 14, [], null, `${pathData1}`],
}

export { definition as facRectangleHistoryCirclePlus }
