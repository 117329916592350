const facLadder = {
  prefix: "fac",
  iconName: "ladder",
  icon: [
    12,
    14,
    [],
    null,
    "M2.18809 0C2.54707 0 2.83809 0.291015 2.83809 0.65V1.85H9.16211V0.65C9.16211 0.291015 9.45312 0 9.81211 0C10.1711 0 10.4621 0.291015 10.4621 0.65V1.85H10.6007C10.9597 1.85 11.2507 2.14102 11.2507 2.5C11.2507 2.85899 10.9597 3.15 10.6007 3.15H10.4621V6.35H10.6007C10.9597 6.35 11.2507 6.64102 11.2507 7C11.2507 7.35899 10.9597 7.65 10.6007 7.65H10.4621V10.85H10.6007C10.9597 10.85 11.2507 11.141 11.2507 11.5C11.2507 11.859 10.9597 12.15 10.6007 12.15H10.4621V13.35C10.4621 13.709 10.1711 14 9.81211 14C9.45312 14 9.16211 13.709 9.16211 13.35V12.15H2.83809V13.35C2.83809 13.709 2.54707 14 2.18809 14C1.8291 14 1.53809 13.709 1.53809 13.35L1.53809 12.15H1.39951C1.04053 12.15 0.749512 11.859 0.749512 11.5C0.749512 11.141 1.04053 10.85 1.39951 10.85H1.53809L1.53809 7.65H1.39951C1.04053 7.65 0.749512 7.35899 0.749512 7C0.749512 6.64102 1.04053 6.35 1.39951 6.35H1.53809L1.53809 3.15H1.39951C1.04053 3.15 0.749512 2.85899 0.749512 2.5C0.749512 2.14102 1.04053 1.85 1.39951 1.85H1.53809L1.53809 0.65C1.53809 0.291015 1.8291 0 2.18809 0ZM2.83809 3.15V6.35H9.16211L9.16211 3.15H2.83809ZM9.16211 7.65H2.83809L2.83809 10.85H9.16211V7.65Z",
  ],
}

export { facLadder }
