const pathData1 =
  "M12.054 4.81734C12.3886 4.81734 12.719 4.88089 13.0292 5.0054L13.3644 5.139C13.7499 5.29254 14.1888 5.10483 14.3427 4.71876C14.4962 4.33334 14.3085 3.89464 13.9226 3.74077L13.5899 3.60726C13.3061 3.49333 13.0097 3.41075 12.7082 3.36259V2.5625C12.7082 2.14659 12.3735 1.81126 11.9579 1.81L11.9556 1.81C11.54 1.81125 11.2053 2.14659 11.2053 2.5625V3.37042C10.0263 3.62913 9.1424 4.68013 9.1424 5.9375C9.1424 7.04132 9.83208 8.0293 10.8709 8.40591L12.5283 9.00826C12.9706 9.16834 13.2659 9.59117 13.2659 10.0625C13.2659 10.6817 12.7626 11.185 12.1431 11.185H11.9884C11.604 11.185 11.2286 11.0857 10.8965 10.896L10.2638 10.5352C9.90273 10.3282 9.44452 10.4541 9.23794 10.814C9.03064 11.1751 9.15679 11.6332 9.5168 11.8398C9.5168 11.8398 9.90206 12.0609 10.1499 12.2008C10.696 12.5091 11.2006 12.6046 11.2006 12.6046V13.4375C11.2006 13.8542 11.5365 14.19 11.9532 14.19C12.3699 14.19 12.7058 13.8542 12.7058 13.4375V12.6319C13.8848 12.3732 14.7687 11.3222 14.7687 10.0648C14.7687 8.96102 14.0789 7.97299 13.04 7.59637L11.3826 6.99399C10.9403 6.83391 10.6453 6.41117 10.6453 5.93984C10.6453 5.32059 11.1486 4.81734 11.768 4.81734H12.054Z"
const pathData2 =
  "M6.302,9 L7.502,12.151 C7.643,12.522 7.458,12.939 7.085,13.08 C6.715,13.22 6.298,13.035 6.157,12.663 L4.857,9.248 L3.67,9.248 L3.67,12.408 C3.67,12.807 3.349,13.127 2.95,13.127 C2.552,13.127 2.231,12.807 2.231,12.408 L2.231,3.592 C2.231,3.193 2.552,2.872 2.95,2.872 L5.067,2.872 C6.827,2.872 8.255,4.3 8.255,6.06 C8.255,7.384 7.448,8.518 6.302,9 Z M3.67,7.81 L5.067,7.81 C6.033,7.81 6.817,7.027 6.817,6.06 C6.817,5.094 6.033,4.311 5.067,4.311 L3.67,4.311 Z"

const definition = {
  prefix: "fac",
  iconName: "brazilian-real-sign",
  icon: [14, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facBrazilianRealSign }
