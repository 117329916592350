const pathData =
  "M3.5,2.5 C3.225,2.5 3,2.725 3,3 L3,13 C3,13.275 3.225,13.5 3.5,13.5 L13.5,13.5 C13.775,13.5 14,13.275 14,13 L14,3 C14,2.725 13.775,2.5 13.5,2.5 L3.5,2.5 Z M1.5,3 C1.5,1.897 2.397,1 3.5,1 L13.5,1 C14.603,1 15.5,1.897 15.5,3 L15.5,13 C15.5,14.103 14.603,15 13.5,15 L3.5,15 C2.397,15 1.5,14.103 1.5,13 L1.5,3 Z M9.15,4.175 L9.15,4.619 C9.453,4.656 9.756,4.741 10.056,4.825 C10.116,4.841 10.172,4.856 10.231,4.875 C10.591,4.975 10.803,5.347 10.703,5.706 C10.603,6.066 10.231,6.275 9.872,6.178 C9.822,6.166 9.775,6.15 9.725,6.138 C9.506,6.075 9.287,6.016 9.066,5.972 C8.653,5.894 8.175,5.931 7.791,6.097 C7.447,6.247 7.162,6.609 7.553,6.859 C7.859,7.056 8.234,7.156 8.591,7.253 C8.666,7.272 8.738,7.294 8.809,7.312 C9.297,7.45 9.919,7.628 10.384,7.947 C10.991,8.363 11.275,9.037 11.141,9.762 C11.012,10.463 10.525,10.922 9.941,11.159 C9.697,11.259 9.431,11.322 9.153,11.353 L9.153,11.828 C9.153,12.2 8.85,12.503 8.478,12.503 C8.106,12.503 7.803,12.2 7.803,11.828 L7.803,11.284 C7.35,11.181 6.906,11.037 6.466,10.894 C6.112,10.778 5.919,10.394 6.037,10.041 C6.156,9.688 6.537,9.494 6.891,9.613 C6.969,9.637 7.047,9.666 7.125,9.691 C7.478,9.809 7.841,9.931 8.203,9.991 C8.734,10.069 9.159,10.022 9.438,9.909 C9.812,9.759 9.991,9.312 9.622,9.062 C9.306,8.847 8.916,8.741 8.544,8.641 C8.472,8.622 8.403,8.603 8.331,8.581 C7.859,8.447 7.269,8.281 6.825,7.997 C6.216,7.606 5.906,6.956 6.037,6.234 C6.162,5.553 6.694,5.1 7.256,4.856 C7.428,4.781 7.612,4.722 7.803,4.678 L7.803,4.175 C7.803,3.803 8.106,3.5 8.478,3.5 C8.85,3.5 9.153,3.803 9.153,4.175 L9.15,4.175 Z"

const definition = {
  prefix: "fac",
  iconName: "square-dollar-sign",
  icon: [17, 16, [], null, pathData],
}

export { definition as facSquareDollarSign }
