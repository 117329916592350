const pathData1 =
  "M3.839,4.174 C3.469,4.359 3.013,4.209 2.827,3.839 C2.749,3.682 2.691,3.574 2.617,3.502 C2.551,3.437 2.466,3.396 2.316,3.396 C2.084,3.396 1.931,3.487 1.832,3.629 C1.728,3.776 1.676,3.987 1.676,4.231 L1.676,11.769 C1.676,12.013 1.728,12.224 1.832,12.371 C1.931,12.513 2.084,12.604 2.316,12.604 C2.466,12.604 2.551,12.563 2.617,12.499 C2.69,12.427 2.747,12.32 2.824,12.165 C3.01,11.791 3.463,11.641 3.837,11.828 C4.21,12.016 4.362,12.465 4.173,12.839 C3.787,13.609 3.196,14.11 2.316,14.11 C1.015,14.11 0.167,13.057 0.167,11.769 L0.167,4.231 C0.167,2.943 1.015,1.89 2.316,1.89 C2.753,1.89 3.122,2.013 3.432,2.237 C3.739,2.46 3.983,2.78 4.176,3.164 C4.362,3.537 4.211,3.989 3.839,4.174 Z"
const pathData2 =
  "M6.876,2.643 L6.876,7.033 L9.545,7.033 L9.545,2.643 C9.545,2.226 9.882,1.89 10.299,1.89 C10.717,1.89 11.054,2.226 11.054,2.643 L11.054,13.357 C11.054,13.774 10.717,14.11 10.299,14.11 C9.882,14.11 9.545,13.774 9.545,13.357 L9.545,8.539 L6.876,8.539 L6.876,13.357 C6.876,13.774 6.539,14.11 6.122,14.11 C5.704,14.11 5.367,13.774 5.367,13.357 L5.367,2.643 C5.367,2.226 5.704,1.89 6.122,1.89 C6.539,1.89 6.876,2.226 6.876,2.643 Z"
const pathData3 =
  "M13.084,1.89 L16.078,1.89 C16.496,1.89 16.833,2.226 16.833,2.643 C16.833,3.06 16.496,3.396 16.078,3.396 L13.839,3.396 L13.839,7.033 L15.219,7.033 C15.637,7.033 15.974,7.368 15.974,7.786 C15.974,8.203 15.637,8.539 15.219,8.539 L13.839,8.539 L13.839,13.357 C13.839,13.774 13.502,14.11 13.084,14.11 C12.666,14.11 12.329,13.774 12.329,13.357 L12.329,2.643 C12.329,2.226 12.666,1.89 13.084,1.89 Z"

const definition = {
  prefix: "fac",
  iconName: "chf-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facChfSign }
