const svgPathData =
  "M0.0273438 17.3624C0.0273438 7.80804 7.83679 0.0627441 17.4698 " +
  "0.0627441H47.3061C56.9391 0.0627441 64.7485 7.80804 64.7485 " +
  "17.3624V252.494L168.845 145.159C172.134 141.768 176.673 139.852 181.416 " +
  "139.852H215.511C230.816 139.852 238.7 158.007 228.185 169.037L135.111 " +
  "266.675L232.995 465.032C238.667 476.526 230.23 489.937 217.328 " +
  "489.937L187.306 489.937C180.618 489.937 174.519 486.144 171.606 " +
  "480.174L90.343 313.637L65.4814 339.718C65.243 339.968 64.9983 340.21 " +
  "64.7485 340.443V472.171C64.7485 481.725 56.9391 489.471 47.3061 " +
  "489.471H17.4698C7.83679 489.471 0.0273438 481.725 0.0273438 " +
  "472.171V17.3624Z M264.455 157.395C264.455 147.841 272.264 140.096 " +
  "281.897 140.096H310.824C320.458 140.096 328.267 147.841 328.267 " +
  "157.395V199.581C358.349 140.433 416.996 129.893 452.409 135.927C459.775 " +
  "137.182 464.512 143.897 464.512 151.31V178.277C464.512 190.947 450.368" +
  "201.162 437.594 201.366C424.161 201.58 406.725 204.349 386.28 " +
  "212.39C343.73 229.125 335.233 263.667 328.914 303.58C328.765 304.522 " +
  "328.546 305.42 328.267 306.268V472.171C328.267 481.725 320.458 489.471 " +
  "310.824 489.471H281.897C272.264 489.471 264.455 481.725 264.455 " +
  "472.171V157.395Z"

const definition = {
  prefix: "fac",
  iconName: "kr-sign",
  icon: [464.5, 489.9, [], null, svgPathData],
}

export { definition as facKrSign }
