const pathData1 =
  "M10.648,12.359 C10.85,12.074 10.785,11.677 10.5,11.474 L7.902,9.61 C9.063,9.43 9.994,8.548 10.25,7.41 L11.023,7.41 C11.374,7.41 11.656,7.126 11.656,6.775 C11.656,6.425 11.374,6.141 11.023,6.141 L10.25,6.141 C10.171,5.79 10.027,5.464 9.834,5.175 L11.023,5.175 C11.374,5.175 11.656,4.891 11.656,4.541 C11.656,4.19 11.374,3.906 11.023,3.906 L5.977,3.906 C5.626,3.906 5.344,4.19 5.344,4.541 C5.344,4.891 5.626,5.175 5.977,5.175 L7.461,5.175 C8.117,5.175 8.68,5.572 8.925,6.141 L5.977,6.141 C5.626,6.141 5.344,6.425 5.344,6.775 C5.344,7.126 5.626,7.41 5.977,7.41 L8.925,7.41 C8.679,7.978 8.116,8.375 7.461,8.375 L5.977,8.375 C5.703,8.375 5.455,8.554 5.373,8.818 C5.291,9.079 5.385,9.365 5.606,9.526 L9.764,12.507 C10.049,12.711 10.444,12.644 10.648,12.359 Z"
const pathData2 =
  "M1.5,3.031 C1.5,1.909 2.409,1 3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 Z M3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-indian-rupee-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquareIndianRupeeSign }
