const pathData1 =
  "M6.459,5.099 C6.339,4.861 6.185,4.655 5.984,4.509 C5.781,4.361 5.539,4.281 5.164,4.281 C4.32,4.281 3.781,4.971 3.781,5.782 L3.781,10.218 C3.781,11.029 4.32,11.719 5.164,11.719 C5.834,11.719 6.216,11.384 6.457,10.903 C6.598,10.621 6.484,10.284 6.205,10.144 C5.926,10.002 5.586,10.115 5.447,10.396 C5.399,10.492 5.375,10.535 5.349,10.56 C5.336,10.572 5.225,10.587 5.164,10.587 C5.067,10.587 5.015,10.552 4.98,10.502 C4.939,10.443 4.911,10.347 4.911,10.218 L4.911,5.782 C4.911,5.653 4.939,5.557 4.98,5.498 C5.015,5.449 5.067,5.413 5.164,5.413 C5.318,5.413 5.336,5.427 5.349,5.44 C5.375,5.466 5.4,5.509 5.448,5.606 C5.588,5.885 5.929,5.997 6.206,5.858 C6.485,5.719 6.597,5.379 6.459,5.099 Z"
const pathData2 =
  "M8.149,4.847 C8.149,4.533 7.897,4.281 7.584,4.281 C7.272,4.281 7.019,4.533 7.019,4.847 L7.019,11.153 C7.019,11.467 7.272,11.719 7.584,11.719 C7.897,11.719 8.149,11.467 8.149,11.153 L8.149,8.44 L9.039,8.44 L9.039,11.153 C9.039,11.467 9.292,11.719 9.604,11.719 C9.916,11.719 10.169,11.467 10.169,11.153 L10.169,4.847 C10.169,4.533 9.916,4.281 9.604,4.281 C9.292,4.281 9.039,4.533 9.039,4.847 L9.039,7.308 L8.149,7.308 L8.149,4.847 Z"
const pathData3 =
  "M10.667,4.847 C10.667,4.533 10.92,4.281 11.232,4.281 L12.498,4.281 C12.81,4.281 13.062,4.533 13.062,4.847 C13.062,5.161 12.81,5.413 12.498,5.413 L11.797,5.413 L11.797,7.308 L12.493,7.308 C12.806,7.308 13.058,7.56 13.058,7.874 C13.058,8.188 12.806,8.44 12.493,8.44 L11.797,8.44 L11.797,11.153 C11.797,11.467 11.544,11.719 11.232,11.719 C10.92,11.719 10.667,11.467 10.667,11.153 L10.667,4.847 Z"
const pathData4 =
  "M1.5,3.031 C1.5,1.909 2.409,1 3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 Z M3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-chf-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3} ${pathData4}`],
}

export { definition as facSquareChfSign }
