const pathData1 =
  "M6.738,4.665 C6.738,4.294 6.437,3.993 6.065,3.993 C5.694,3.993 5.393,4.294 5.393,4.665 L5.393,11.306 C5.393,11.678 5.694,11.979 6.065,11.979 C6.437,11.979 6.738,11.678 6.738,11.306 L6.738,9.519 L6.858,9.39 L8.053,11.624 C8.228,11.951 8.636,12.075 8.963,11.899 C9.29,11.724 9.414,11.317 9.239,10.989 L7.837,8.369 L8.99,7.216 C9.253,6.953 9.253,6.527 8.99,6.265 C8.727,6.002 8.302,6.002 8.039,6.265 L6.738,7.566 L6.738,4.665 Z"
const pathData2 =
  "M11.116,8.977 C11.116,8.047 11.796,7.433 12.601,7.433 C12.972,7.433 13.273,7.132 13.273,6.761 C13.273,6.389 12.972,6.088 12.601,6.088 C12.043,6.088 11.517,6.247 11.075,6.529 C10.98,6.272 10.733,6.088 10.443,6.088 C10.072,6.088 9.771,6.389 9.771,6.761 L9.771,11.32 C9.771,11.692 10.072,11.993 10.443,11.993 C10.815,11.993 11.116,11.692 11.116,11.32 L11.116,8.977 Z"
const pathData3 =
  "M1.5,3 C1.5,1.897 2.397,1 3.5,1 L13.5,1 C14.603,1 15.5,1.897 15.5,3 L15.5,13 C15.5,14.103 14.603,15 13.5,15 L3.5,15 C2.397,15 1.5,14.103 1.5,13 Z M14,3 C14,2.725 13.775,2.5 13.5,2.5 L3.5,2.5 C3.225,2.5 3,2.725 3,3 L3,13 C3,13.275 3.225,13.5 3.5,13.5 L13.5,13.5 C13.775,13.5 14,13.275 14,13 Z"

const definition = {
  prefix: "fac",
  iconName: "square-kr-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareKrSign }
