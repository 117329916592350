const pathData1 =
  "M1.33268 2.38281C1.7782 2.38281 2.13815 2.74141 2.13815 3.18527V12.0123H4.55922C5.00474 12.0123 5.36468 12.3709 5.36468 12.8147C5.36468 13.2586 5.00474 13.6172 4.55922 13.6172H1.33268C0.887157 13.6172 0.527214 13.2586 0.527214 12.8147V3.18527C0.527214 2.74141 0.887157 2.38281 1.33268 2.38281Z"
const pathData2 =
  "M7.74176 2.38281C6.85323 2.38281 6.13083 3.10251 6.13083 3.98772V12.0123C6.13083 12.8975 6.85323 13.6172 7.74176 13.6172H9.5219C9.96742 13.6172 10.3274 13.2586 10.3274 12.8147C10.3274 12.3709 9.96742 12.0123 9.5219 12.0123H7.74176V8.80245H8.87523C9.32075 8.80245 9.6807 8.44386 9.6807 8C9.6807 7.55614 9.32075 7.19754 8.87523 7.19754H7.74176V3.98772H9.5219C9.96742 3.98772 10.3274 3.62913 10.3274 3.18527C10.3274 2.74141 9.96742 2.38281 9.5219 2.38281H7.74176Z"
const pathData3 =
  "M11.2784 3.18527C11.2784 2.74141 11.6404 2.38281 12.0885 2.38281H15.6628C16.1108 2.38281 16.4728 2.74141 16.4728 3.18527C16.4728 3.62913 16.1108 3.98772 15.6628 3.98772H14.6856V12.0123H15.6628C16.1108 12.0123 16.4728 12.3709 16.4728 12.8147C16.4728 13.2586 16.1108 13.6172 15.6628 13.6172H12.0885C11.6404 13.6172 11.2784 13.2586 11.2784 12.8147C11.2784 12.3709 11.6404 12.0123 12.0885 12.0123H13.0656V3.98772H12.0885C11.6404 3.98772 11.2784 3.62913 11.2784 3.18527Z"

const definition = {
  prefix: "fac",
  iconName: "romanian-leu-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facRomanianLeuSign }
