const facListOl = {
  prefix: "fac",
  iconName: "list-ol",
  icon: [
    14,
    14,
    [],
    null,
    "M0.547925 1.53128C0.547925 1.16761 0.8405 0.875031 1.20417 0.875031H2.07916C2.44283 " +
      "0.875031 2.73541 1.16761 2.73541 1.53128V4.81253H3.1729C3.53657 4.81253 3.82915 " +
      "5.10511 3.82915 5.46878C3.82915 5.83245 3.53657 6.12503 3.1729 6.12503H0.985421C0.621752 " +
      "6.12503 0.329176 5.83245 0.329176 5.46878C0.329176 5.10511 0.621752 4.81253 0.985421 " +
      "4.81253H1.42292V2.18753H1.20417C0.8405 2.18753 0.547925 1.89495 0.547925 " +
      "1.53128ZM2.26236 9.32972C2.08463 9.12737 1.76198 9.14105 1.60612 9.36253L1.29987 " +
      "9.78636C1.08933 10.0817 0.679173 10.15 0.383863 9.93948C0.0885533 9.72894 0.0201945 " +
      "9.31878 0.23074 9.02347L0.534253 8.59691C1.18229 7.68909 2.51119 7.62347 3.24673 " +
      "8.46292C3.82915 9.13011 3.81548 10.1282 3.21665 10.7789L2.2651 11.8125H3.1729C3.53657 " +
      "11.8125 3.82915 12.1051 3.82915 12.4688C3.82915 12.8325 3.53657 13.125 3.1729 " +
      "13.125H0.766673C0.506909 13.125 0.269021 12.9719 0.165115 12.7313C0.0612098 12.4907 " +
      "0.107694 12.2145 0.282692 12.0231L2.25143 9.89027C2.39635 9.73167 2.39908 9.49105 " +
      "2.25963 9.32972H2.26236ZM5.79788 1.96878H13.2353C13.599 1.96878 13.8916 2.26136 " +
      "13.8916 2.62503C13.8916 2.9887 13.599 3.28128 13.2353 3.28128H5.79788C5.43421 3.28128 " +
      "5.14164 2.9887 5.14164 2.62503C5.14164 2.26136 5.43421 1.96878 5.79788 1.96878ZM5.79788 " +
      "6.34378H13.2353C13.599 6.34378 13.8916 6.63636 13.8916 7.00003C13.8916 7.3637 13.599 " +
      "7.65628 13.2353 7.65628H5.79788C5.43421 7.65628 5.14164 7.3637 5.14164 7.00003C5.14164 " +
      "6.63636 5.43421 6.34378 5.79788 6.34378ZM5.79788 10.7188H13.2353C13.599 10.7188 " +
      "13.8916 11.0114 13.8916 11.375C13.8916 11.7387 13.599 12.0313 13.2353 " +
      "12.0313H5.79788C5.43421 12.0313 5.14164 11.7387 5.14164 11.375C5.14164 11.0114 " +
      "5.43421 10.7188 5.79788 10.7188Z",
  ],
}

export { facListOl }
