const pathData1 =
  "M2.15625 0.86792H5.65625C7.70703 0.86792 9.375 2.53589 9.375 4.58667C9.375 6.36401 8.11719 7.84058 6.47656 8.22339L9.23828 12.0789C9.45703 12.3796 9.375 12.7898 9.10156 13.0085C8.80078 13.2273 8.39062 13.1453 8.17188 12.8445L4.91797 8.30542H1.9375V12.4617C1.9375 12.8445 1.63672 13.1179 1.28125 13.1179C0.898438 13.1179 0.625 12.8445 0.625 12.4617V7.64917V2.39917C0.625 1.57886 1.30859 0.86792 2.15625 0.86792Z"
const pathData2 =
  "M5.4375 6.99292H5.65625C6.96875 6.99292 8.0625 5.92651 8.0625 4.58667C8.0625 3.27417 6.96875 2.18042 5.65625 2.18042H2.15625C2.01953 2.18042 1.9375 2.28979 1.9375 2.39917V6.99292H5.4375Z"

const definition = {
  prefix: "fac",
  iconName: "south-african-rand-sign",
  icon: [9, 14, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSouthAfricanRandSign }
