const facFolderTree = {
  prefix: "fac",
  iconName: "folder-tree",
  icon: [
    16,
    14,
    [],
    null,
    "M1.4375 0.65625C1.4375 0.292578 1.14492 0 0.78125 0C0.417578 0 " +
      "0.125 0.292578 0.125 0.65625V3.71875V10.7188C0.125 11.5637 0.811328 " +
      "12.25 1.65625 12.25H7.125V10.9375H1.65625C1.53594 10.9375 1.4375 10.8391 " +
      "1.4375 10.7188V4.375H7.125V3.0625H1.4375V0.65625ZM9.3125 " +
      "4.8125V1.3125H10.5184L10.9832 1.77734C11.2457 2.03984 11.6012 2.1875 " +
      "11.973 2.1875H14.5625V4.8125H9.3125ZM8 0.875V5.25C8 5.73398 8.39102 " +
      "6.125 8.875 6.125H15C15.484 6.125 15.875 5.73398 15.875 5.25V1.75C15.875 " +
      "1.26602 15.484 0.875 15 0.875H11.973C11.9512 0.875 11.9266 0.866797 11.9102 " +
      "0.850391L11.3195 0.257031C11.1555 0.0929687 10.934 0 10.7016 0H8.875C8.39102 " +
      "0 8 0.391016 8 0.875ZM9.3125 12.6875V9.1875H10.5184L10.9832 9.65234C11.2457 " +
      "9.91484 11.6012 10.0625 11.973 10.0625H14.5625V12.6875H9.3125ZM8 8.75V13.125C8 " +
      "13.609 8.39102 14 8.875 14H15C15.484 14 15.875 13.609 15.875 13.125V9.625C15.875 " +
      "9.14102 15.484 8.75 15 8.75H11.973C11.9512 8.75 11.9266 8.7418 11.9102 " +
      "8.72539L11.3168 8.13203C11.1527 7.96797 10.9313 7.875 10.6988 7.875H8.875C8.39102 " +
      "7.875 8 8.26602 8 8.75Z",
  ],
}

export { facFolderTree }
