const pathData1 =
  "M10.028,9.568 L10.029,9.568 L10.439,9.803 L10.439,9.803 C10.64,9.918 10.868,9.979 11.101,9.979 L11.201,9.979 C11.551,9.979 11.835,9.693 11.835,9.343 C11.835,9.076 11.668,8.836 11.419,8.746 L11.419,8.746 L10.343,8.354 C10.315,8.343 10.287,8.332 10.259,8.321 C9.594,8.04 9.159,7.385 9.159,6.657 C9.159,5.82 9.728,5.116 10.498,4.911 L10.498,4.46 C10.498,4.137 10.758,3.876 11.079,3.875 L11.081,3.875 L11.081,3.875 C11.403,3.876 11.663,4.137 11.663,4.46 L11.663,4.901 C11.838,4.934 12.01,4.985 12.175,5.052 L12.391,5.139 L12.391,5.139 C12.69,5.259 12.836,5.599 12.717,5.899 C12.598,6.199 12.258,6.345 11.959,6.226 L11.959,6.226 L11.741,6.139 L11.741,6.138 C11.665,6.108 11.587,6.083 11.508,6.065 C11.389,6.037 11.267,6.023 11.143,6.023 L10.958,6.023 C10.855,6.023 10.757,6.048 10.671,6.092 C10.465,6.197 10.324,6.412 10.324,6.659 C10.324,6.82 10.385,6.971 10.487,7.085 C10.555,7.16 10.641,7.22 10.74,7.256 L10.74,7.256 L11.816,7.648 C12.528,7.907 13,8.586 13,9.344 C13,10.182 12.431,10.886 11.661,11.09 L11.661,11.54 C11.661,11.864 11.401,12.125 11.078,12.125 C10.755,12.125 10.495,11.864 10.495,11.54 L10.495,11.073 C10.274,11.018 10.06,10.932 9.861,10.818 L9.45,10.582 L9.45,10.582 C9.171,10.422 9.073,10.066 9.234,9.785 C9.394,9.505 9.748,9.407 10.028,9.568 Z"
const pathData2 =
  "M4.107,6.299 C4.107,6.299 4.062,6.603 4.139,6.976 C4.215,7.348 4.363,7.548 4.363,7.548 C4.484,7.745 4.636,7.904 4.797,8.031 C5.215,8.362 5.778,8.544 6.221,8.688 L6.227,8.69 L6.233,8.691 C6.236,8.692 6.238,8.692 6.246,8.695 L6.25,8.696 C6.252,8.697 6.255,8.698 6.259,8.699 L6.26,8.7 L6.261,8.7 C6.77,8.864 7.141,8.993 7.381,9.183 C7.487,9.268 7.54,9.344 7.568,9.416 C7.595,9.487 7.618,9.604 7.588,9.805 C7.552,10.034 7.434,10.202 7.198,10.317 L7.198,10.317 C6.944,10.441 6.555,10.492 6.07,10.407 C5.843,10.365 5.446,10.212 5.096,10.077 L5.076,10.07 C4.998,10.038 4.924,10.01 4.856,9.985 L4.856,9.983 L4.856,9.985 C4.539,9.867 4.217,10.066 4.123,10.391 C4.032,10.708 4.176,11.068 4.48,11.187 L4.48,11.187 L4.481,11.187 C4.495,11.193 4.51,11.198 4.525,11.204 L4.525,11.204 L4.526,11.204 C4.565,11.219 4.607,11.234 4.654,11.253 L4.654,11.253 L4.662,11.256 C4.998,11.387 5.537,11.596 5.88,11.659 L5.892,11.662 L5.895,11.662 C6.521,11.771 7.146,11.729 7.661,11.478 C8.199,11.217 8.613,10.725 8.72,10.017 C8.78,9.62 8.752,9.249 8.621,8.915 C8.492,8.583 8.281,8.337 8.05,8.155 C7.632,7.824 7.069,7.642 6.626,7.498 L6.62,7.496 L6.614,7.495 C6.611,7.494 6.609,7.494 6.601,7.491 C6.598,7.49 6.594,7.488 6.588,7.487 L6.587,7.486 L6.586,7.486 C6.078,7.322 5.706,7.193 5.466,7.003 C5.36,6.918 5.307,6.842 5.279,6.77 C5.252,6.699 5.229,6.582 5.259,6.381 C5.294,6.153 5.412,5.985 5.647,5.87 L5.647,5.87 C5.904,5.746 6.292,5.694 6.778,5.781 L6.778,5.781 L6.779,5.781 C6.94,5.808 7.439,5.919 7.585,5.964 L7.586,5.964 L7.586,5.964 C7.909,6.061 8.218,5.839 8.294,5.511 C8.369,5.19 8.206,4.835 7.89,4.74 C7.688,4.679 7.144,4.56 6.962,4.528 C6.333,4.416 5.703,4.456 5.185,4.709 C4.646,4.971 4.233,5.463 4.126,6.171 L4.107,6.299 Z"
const pathData3 =
  "M3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 L1.5,3.031 C1.5,1.909 2.409,1 3.531,1 Z M13.469,2.531 L3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-singapore-dollar-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareSingaporeDollarSign }
