const pathData1 =
  "M14.8054 0.874878C13.8942 0.874878 13.1554 1.61361 13.1554 2.52488V6.7799V11.4699C13.1554 12.3812 13.8942 13.1199 14.8054 13.1199H17.783C18.142 13.1199 18.433 12.8289 18.433 12.4699C18.433 12.1109 18.142 11.8199 17.783 11.8199H14.8054C14.6121 11.8199 14.4554 11.6632 14.4554 11.4699V7.4299H16.9434C17.3023 7.4299 17.5934 7.13888 17.5934 6.7799C17.5934 6.42091 17.3023 6.1299 16.9434 6.1299H14.4554V2.52488C14.4554 2.33158 14.6121 2.17488 14.8054 2.17488H17.783C18.142 2.17488 18.433 1.88386 18.433 1.52488C18.433 1.16589 18.142 0.874878 17.783 0.874878H14.8054Z"
const pathData2 =
  "M3.46245 0.874964C3.77116 0.875296 4.03706 1.09273 4.09867 1.39523L5.99171 10.689L5.99253 10.693L6.33896 12.3413C6.4128 12.6926 6.18785 13.0373 5.83654 13.1111C5.48523 13.185 5.14059 12.96 5.06676 12.6087L4.82801 11.4727H2.08023L1.85495 12.6022C1.78473 12.9542 1.44241 13.1827 1.09036 13.1125C0.738314 13.0422 0.509845 12.6999 0.580064 12.3479L0.909634 10.6955L0.910424 10.6916L0.910436 10.6916L2.82511 1.39386C2.88738 1.09149 3.15374 0.874632 3.46245 0.874964Z"
const pathData3 =
  "M4.55986 10.1727L3.45827 4.76453L2.34457 10.1727H4.55986ZM8.37489 2.175C8.29237 2.175 8.2252 2.24183 8.2252 2.325V11.675C8.2252 11.7578 8.29235 11.825 8.37523 11.825H8.87242C9.26937 11.825 9.86074 11.5063 10.3907 10.6579C10.9032 9.83749 11.2826 8.62245 11.2826 7.12424C11.2826 5.62274 10.909 4.33806 10.3914 3.44896C9.85056 2.51986 9.25635 2.175 8.87242 2.175H8.37489ZM6.9252 2.325C6.9252 1.52451 7.57375 0.875 8.37489 0.875H8.87242C9.95688 0.875 10.8928 1.72619 11.5149 2.79494C12.1603 3.90368 12.5826 5.41862 12.5826 7.12424C12.5826 8.83316 12.1512 10.2935 11.4933 11.3467C10.8528 12.3719 9.91408 13.125 8.87242 13.125H8.37524C7.57446 13.125 6.9252 12.4759 6.9252 11.675V2.325Z"

const definition = {
  prefix: "fac",
  iconName: "uae-dirham-text-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facUaeDirhamTextSign }
