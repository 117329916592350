const pathData1 =
  "M9.547,3.991 C9.316,3.914 9.076,3.875 8.833,3.875 C7.588,3.875 6.578,4.886 6.578,6.132 L6.578,7.062 L6.437,7.062 C6.074,7.062 5.781,7.356 5.781,7.719 C5.781,8.082 6.074,8.375 6.437,8.375 L6.578,8.375 L6.578,8.989 C6.578,9.255 6.506,9.517 6.368,9.744 L5.875,10.568 C5.755,10.771 5.75,11.023 5.867,11.229 C5.984,11.433 6.2,11.562 6.437,11.562 L10.685,11.562 C11.048,11.562 11.341,11.269 11.341,10.906 C11.341,10.543 11.048,10.25 10.685,10.25 L7.588,10.25 C7.786,9.86 7.89,9.428 7.89,8.989 L7.89,8.375 L9.623,8.375 C9.986,8.375 10.279,8.082 10.279,7.719 C10.279,7.356 9.986,7.062 9.623,7.062 L7.89,7.062 L7.89,6.132 C7.89,5.611 8.312,5.188 8.833,5.188 C8.935,5.188 9.036,5.204 9.132,5.236 L10.48,5.686 C10.824,5.801 11.195,5.614 11.31,5.272 C11.425,4.929 11.238,4.556 10.896,4.441 L9.547,3.991 Z"
const pathData2 =
  "M1.5,3.031 C1.5,1.909 2.409,1 3.531,1 L13.469,1 C14.591,1 15.5,1.909 15.5,3.031 L15.5,12.969 C15.5,14.091 14.591,15 13.469,15 L3.531,15 C2.409,15 1.5,14.091 1.5,12.969 Z M3.531,2.531 C3.255,2.531 3.031,2.755 3.031,3.031 L3.031,12.969 C3.031,13.245 3.255,13.469 3.531,13.469 L13.469,13.469 C13.745,13.469 13.969,13.245 13.969,12.969 L13.969,3.031 C13.969,2.755 13.745,2.531 13.469,2.531 Z"

const definition = {
  prefix: "fac",
  iconName: "square-pound-sign",
  icon: [17, 16, [], null, `${pathData1} ${pathData2}`],
}

export { definition as facSquarePoundSign }
