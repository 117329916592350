const svgPathData =
  "M0.65625 0.3125C0.292578 0.3125 0 0.605078 0 0.96875V2.28125C0" +
  " 2.64492 0.292578 2.9375 0.65625 2.9375V6V9.5C0.65625 10.3449 1.34258" +
  " 11.0312 2.1875 11.0312H3.5C3.5 11.3949 3.79258 11.6875 4.15625" +
  " 11.6875H5.46875C5.83242 11.6875 6.125 11.3949 6.125 11.0312V9.71875C6.125" +
  " 9.35508 5.83242 9.0625 5.46875 9.0625H4.15625C3.79258 9.0625 3.5 9.35508" +
  " 3.5 9.71875H2.1875C2.06719 9.71875 1.96875 9.62031 1.96875 9.5V6.65625H3.5C3.5" +
  " 7.01992 3.79258 7.3125 4.15625 7.3125H5.46875C5.83242 7.3125 6.125 7.01992" +
  " 6.125 6.65625V5.34375C6.125 4.98008 5.83242 4.6875 5.46875 4.6875H4.15625C3.79258" +
  " 4.6875 3.5 4.98008 3.5 5.34375H1.96875V2.9375C2.33242 2.9375 2.625 2.64492 2.625" +
  " 2.28125V0.96875C2.625 0.605078 2.33242 0.3125 1.96875 0.3125H0.65625ZM4.375" +
  " 1.625C4.375 1.98867 4.66758 2.28125 5.03125 2.28125H13.3438C13.7074 2.28125" +
  " 14 1.98867 14 1.625C14 1.26133 13.7074 0.96875 13.3438 0.96875H5.03125C4.66758" +
  " 0.96875 4.375 1.26133 4.375 1.625ZM7.875 6C7.875 6.36367 8.16758 6.65625 8.53125" +
  " 6.65625H13.3438C13.7074 6.65625 14 6.36367 14 6C14 5.63633 13.7074 5.34375 13.3438" +
  " 5.34375H8.53125C8.16758 5.34375 7.875 5.63633 7.875 6ZM7.875 10.375C7.875 10.7387" +
  " 8.16758 11.0312 8.53125 11.0312H13.3438C13.7074 11.0312 14 10.7387 14 10.375C14" +
  " 10.0113 13.7074 9.71875 13.3438 9.71875H8.53125C8.16758 9.71875 7.875 10.0113 7.875 10.375Z"

const definition = {
  prefix: "fac",
  iconName: "chart-sections",
  icon: [14, 12, [], null, svgPathData],
}

export { definition as facChartSections }
