const pathData1 =
  "M10.875 3.1875C11.1156 3.1875 11.3125 3.38437 11.3125 3.625V12.375C11.3125 12.6156 11.1156 12.8125 10.875 12.8125H2.125C1.88437 12.8125 1.6875 12.6156 1.6875 12.375V3.625C1.6875 3.38437 1.88437 3.1875 2.125 3.1875H10.875ZM2.125 1.875C1.15977 1.875 0.375 2.65977 0.375 3.625V12.375C0.375 13.3402 1.15977 14.125 2.125 14.125H10.875C11.8402 14.125 12.625 13.3402 12.625 12.375V3.625C12.625 2.65977 11.8402 1.875 10.875 1.875H2.125Z"
const pathData2 =
  "M3.51991 4.84753C3.76224 4.84776 3.96953 5.02174 4.01178 5.26036L4.80912 9.76338L4.80935 9.7647L4.95511 10.5625C5.00474 10.8341 4.82476 11.0946 4.55311 11.1442C4.28147 11.1938 4.02102 11.0139 3.97139 10.7422L3.90008 10.3519H3.13386L3.06695 10.7378C3.01977 11.0099 2.76096 11.1922 2.48888 11.145C2.2168 11.0978 2.03448 10.839 2.08165 10.5669L2.22045 9.76645L2.22091 9.76377L2.22092 9.76377L3.02726 5.25943C3.06996 5.02089 3.27758 4.84731 3.51991 4.84753Z"
const pathData3 =
  "M3.31055 9.35187H3.7207L3.51675 8.20004L3.31055 9.35187ZM6.19947 6.00562V10.0127C6.3236 9.93054 6.46388 9.80711 6.60324 9.62987C6.89421 9.25982 7.12288 8.71956 7.12288 8.06018C7.12288 7.39689 6.89507 6.82115 6.59856 6.41702C6.46785 6.23886 6.32906 6.10127 6.19947 6.00562ZM5.42853 4.98435C5.57439 4.86876 5.73721 4.84759 5.84699 4.84759C6.15509 4.84759 6.45077 4.96986 6.70149 5.13663C6.95729 5.30677 7.19809 5.54368 7.40483 5.82547C7.81843 6.38921 8.12288 7.16976 8.12288 8.06018C8.12288 8.95451 7.81207 9.71035 7.38933 10.248C6.98596 10.761 6.41287 11.1524 5.84698 11.1524C5.45293 11.1524 5.19947 10.8257 5.19947 10.4973V5.71185C5.19947 5.68807 5.19853 5.66973 5.19713 5.64244L5.1947 5.59169L5.19469 5.59151C5.19284 5.54871 5.18898 5.45962 5.20553 5.36666C5.22373 5.26437 5.27563 5.10551 5.42853 4.98435ZM9.83887 4.84753C9.01044 4.84753 8.33887 5.51911 8.33887 6.34753V7.89336V9.64992C8.33887 10.4783 9.01044 11.1499 9.83887 11.1499H10.3317C10.6079 11.1499 10.8317 10.9261 10.8317 10.6499C10.8317 10.3738 10.6079 10.1499 10.3317 10.1499H9.83887C9.56273 10.1499 9.33887 9.92606 9.33887 9.64992V8.39336H10.0166C10.2927 8.39336 10.5166 8.1695 10.5166 7.89336C10.5166 7.61722 10.2927 7.39336 10.0166 7.39336H9.33887V6.34753C9.33887 6.07139 9.56273 5.84753 9.83887 5.84753H10.3317C10.6079 5.84753 10.8317 5.62368 10.8317 5.34753C10.8317 5.07139 10.6079 4.84753 10.3317 4.84753H9.83887Z"

const definition = {
  prefix: "fac",
  iconName: "square-uae-dirham-text-sign",
  icon: [13, 14.25, [], null, `${pathData1} ${pathData2} ${pathData3}`],
}

export { definition as facSquareUaeDirhamTextSign }
