const pathData =
  "M3,1 L13,1 C14.103,1 15,1.897 15,3 L15,13 C15,14.103 14.103,15 13,15 L3,15 C1.897,15 1,14.103 1,13 L1,3 C1,1.897 1.897,1 3,1 Z M13,2.5 L3,2.5 C2.725,2.5 2.5,2.725 2.5,3 L2.5,13 C2.5,13.275 2.725,13.5 3,13.5 L13,13.5 C13.275,13.5 13.5,13.275 13.5,13 L13.5,3 C13.5,2.725 13.275,2.5 13,2.5 Z M5.956,4.446 L5.956,7.347 L7.258,6.046 C7.52,5.783 7.946,5.783 8.209,6.046 C8.471,6.309 8.471,6.734 8.209,6.997 L7.055,8.15 L8.457,10.771 C8.633,11.098 8.509,11.505 8.182,11.681 C7.854,11.856 7.447,11.732 7.272,11.405 L6.077,9.172 L5.956,9.3 L5.956,11.088 C5.956,11.459 5.655,11.76 5.284,11.76 C4.913,11.76 4.612,11.459 4.612,11.088 L4.612,4.446 C4.612,4.075 4.913,3.774 5.284,3.774 C5.655,3.774 5.956,4.075 5.956,4.446 Z M10.334,8.759 L10.334,11.102 C10.334,11.473 10.033,11.774 9.662,11.774 C9.291,11.774 8.99,11.473 8.99,11.102 L8.99,6.542 C8.99,6.17 9.291,5.869 9.662,5.869 C9.952,5.869 10.199,6.053 10.293,6.31 C10.736,6.028 11.261,5.869 11.819,5.869 C12.191,5.869 12.492,6.17 12.492,6.542 C12.492,6.913 12.191,7.214 11.819,7.214 C11.015,7.214 10.334,7.828 10.334,8.759 Z M11.618,11.766 C11.19,11.766 10.844,11.419 10.844,10.992 C10.844,10.564 11.19,10.218 11.618,10.218 C12.045,10.218 12.392,10.564 12.392,10.992 C12.392,11.419 12.045,11.766 11.618,11.766 Z"

const definition = {
  prefix: "fac",
  iconName: "square-danish-krone-sign",
  icon: [17, 16, [], null, pathData],
}

export { definition as facSquareDanishKroneSign }
